import { PlayPauseElement, isPlayPauseElement } from '../type-guards/play-pause-element';

/**
 * MrPlayPauseToggle is a self contained play/pause button for HTMLMediaElement.
 *
 * Supports :
 * - pause
 * - play
 */
export class MrPlayPauseToggle extends HTMLElement {

	/**
	 * "data-for" is observed.
	 * You can update this attribute in DOM.
	 */
	static get observedAttributes(): Array<string> {
		return [
			'data-for',
		];
	}

	/**
	 * When disabled clicks will not toggle playback.
	 *
	 * External updates to playback will still update the state here.
	 */
	get disabled(): boolean {
		return this.hasAttribute( 'disabled' );
	}

	set disabled( value: boolean ) {
		if ( value === this.disabled ) {
			return;
		}

		if ( value ) {
			this.setAttribute( 'disabled', '' );

			return;
		}

		this.removeAttribute( 'disabled' );
	}

	attributeChangedCallback( attrName: string, oldVal: string|null, newVal: string|null ): void {
		// Event listeners are added
		if ( 'data-for' === attrName ) {
			this.stopListening( oldVal );

			if ( null !== newVal ) {
				this.startListening( newVal );
			}

			return;
		}
	}

	#clickHandler = ( e: MouseEvent ): void => {
		// always prevent default button behavior
		e.preventDefault();

		// when disabled do nothing
		if ( this.disabled ) {
			return;
		}

		const media = this.getAttachedMedia();
		if ( !media ) {
			return;
		}

		if ( !media.paused ) {
			media.pause();

			return;
		}

		if ( this.hasAttribute( 'data-playback-starts-from-zero' ) ) {
			media.currentTime = 0;
		}

		media.setAttribute( 'data-playback-requested', '' );
		const playPromise = media.play();
		if ( playPromise instanceof Promise ) {
			playPromise.then( () => {
				return; // noop.
			} ).catch( () => {
				// quickly playing and pausing can cause the video to be paused again before playback starts.
				// these are expected errors and normal behavior.
				return; // noop.
			} );
		}
	};

	// The video can start playing before this element is alive
	// Lister for a single timeupdate event;
	#timeupdateHandler = (): void => {
		requestAnimationFrame( () => {
			this.setStateAttribute();
		} );
	};

	#playHandler = (): void => {
		requestAnimationFrame( () => {
			this.setStateAttribute();
		} );
	};

	#pauseHandler = (): void => {
		requestAnimationFrame( () => {
			this.setStateAttribute();
		} );
	};

	connectedCallback(): void {
		this.startListening();
	}

	disconnectedCallback(): void {
		this.stopListening();

		this.removeAttribute( 'data-playing' );
	}

	private startListening( onId?: string|null|undefined ): void {
		this.addEventListener( 'click', this.#clickHandler );

		requestAnimationFrame( () => {
			const media = this.getAttachedMedia( onId );
			if ( media ) {
				if ( media.paused ) {
					this.removeAttribute( 'data-playing' );
				} else {
					this.setAttribute( 'data-playing', '' );
					if ( this.hasAttribute( 'data-playback-requested' ) ) {
						this.removeAttribute( 'data-playback-requested' );
					}
				}

				media.addEventListener( 'timeupdate', this.#timeupdateHandler, {
					once: true,
				} );

				media.addEventListener( 'play', this.#playHandler );
				media.addEventListener( 'pause', this.#pauseHandler );
			}
		} );
	}

	private stopListening( onId?: string|null|undefined ): void {
		this.removeEventListener( 'click', this.#clickHandler );

		const media = this.getAttachedMedia( onId );
		if ( media ) {
			media.removeEventListener( 'play', this.#playHandler );
			media.removeEventListener( 'pause', this.#pauseHandler );
		}
	}

	private setStateAttribute(): void {
		if ( this.hasAttribute( 'data-playback-requested' ) ) {
			this.removeAttribute( 'data-playback-requested' );
		}

		const media = this.getAttachedMedia();
		if ( media && !media.paused ) {
			this.setAttribute( 'data-playing', '' );

			const labelledElement = this.querySelector( '[data-aria-label-playing]' );
			if ( labelledElement ) {
				labelledElement.setAttribute( 'aria-label', labelledElement.getAttribute( 'data-aria-label-playing' ) || '' );
			}

			return;
		}

		this.removeAttribute( 'data-playing' );

		const labelledElement = this.querySelector( '[data-aria-label-paused]' );
		if ( labelledElement ) {
			labelledElement.setAttribute( 'aria-label', labelledElement.getAttribute( 'data-aria-label-paused' ) || '' );
		}
	}

	private getAttachedMedia( withId?: string|null|undefined ): PlayPauseElement | null {
		const id = withId || this.getAttribute( 'data-for' );
		if ( !id ) {
			return null;
		}

		const media = document.getElementById( id );
		if ( !media || !isPlayPauseElement( media ) ) {
			return null;
		}

		return media;
	}
}
