export function isPlayPauseElement( element: HTMLElement ): element is PlayPauseElement {
	return ( 'paused' in element ) &&
		( 'currentTime' in element ) &&
		( 'play' in element && typeof element.play === 'function' ) &&
		( 'pause' in element && typeof element.pause === 'function' ) &&
		( 'addEventListener' in element );
}

export type PlayPauseElement = HTMLElement & {
	paused: boolean;
	currentTime: number;
	play(): Promise<void>;
	pause(): void;
};
