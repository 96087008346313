import { MrModalDialog } from '@mrhenry/wp--mr-interactive';
import { MrVideo } from './mr-video';

class MrVideoOverlay extends MrModalDialog {
	override async willOpen() {
		await super.willOpen();
		document.body.classList.add( 'is-showing-modal-dialog' );

		const mrVideo = this.querySelector<MrVideo>( 'mr-video' );
		if ( !mrVideo ) {
			return;
		}

		mrVideo.setupVideoPlayback();
	}

	override async didOpen(): Promise<void> {
		await super.didOpen();

		const mrVideo = this.querySelector<MrVideo>( 'mr-video' );
		if ( !mrVideo ) {
			return;
		}

		await mrVideo.play();
	}

	override async willClose() {
		const mrVideo = this.querySelector<MrVideo>( 'mr-video' );
		if ( !mrVideo ) {
			return;
		}

		await mrVideo.pause();
		await super.willClose();
		document.body.classList.remove( 'is-showing-modal-dialog' );
	}

	override openAnimations() {
		if ( !( 'KeyframeEffect' in window ) ) {
			return [];
		}

		return [
			new KeyframeEffect(
				this.querySelector( '.modal-dialog__content' ),
				[
					{
						opacity: 0,
					},
					{
						opacity: 1,
					},
				],
				{
					duration: 384,
					easing: 'ease-in-out',
					fill: 'forwards',
				}
			),
		];
	}

	override closeAnimations() {
		if ( !( 'KeyframeEffect' in window ) ) {
			return [];
		}

		return [
			new KeyframeEffect(
				this.querySelector( '.modal-dialog__content' ),
				[
					{
						opacity: 1,
					},
					{
						opacity: 0,
					},
				],
				{
					duration: 128,
					easing: 'ease-in-out',
					fill: 'forwards',
				}
			),
		];
	}
}

customElements.define( 'mr-video-overlay', MrVideoOverlay );
