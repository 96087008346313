/** Simple accordion pattern
 * @see https://www.w3.org/TR/wai-aria-practices-1.1/examples/accordion/accordion.html
 */

export class MrAccordion extends HTMLElement {
	// Handle click events
	#clickHandler = ( event: MouseEvent ): void => {
		const target = event.target;

		if ( !target || !( target instanceof Element ) ) {
			return;
		}

		if ( !target.hasAttribute( 'accordion-trigger' ) ) {
			return;
		}

		const handled = this.handleToggleEvent( target, false );
		if ( handled ) {
			event.preventDefault();
		}

	};

	#beforeMatchHandler = ( event: Event ): void => {
		if ( !event.target ) {
			return;
		}

		if ( !( event.target instanceof Element ) ) {
			return;
		}

		const trigger = this.findTriggerFromPanelContents( event.target );
		if ( trigger ) {
			this.handleToggleEvent( trigger, true );
		}
	};

	// Life cycle
	connectedCallback() :void {
		this._addEventListeners();
	}

	disconnectedCallback(): void {
		this._removeEventListeners();
	}

	_addEventListeners(): void {
		this.addEventListener( 'click', this.#clickHandler );
		this.addEventListener( 'beforematch', this.#beforeMatchHandler );
		addKeypressEventListener( this.tagName );
	}

	_removeEventListeners(): void {
		this.removeEventListener( 'click', this.#clickHandler );
		this.removeEventListener( 'beforematch', this.#beforeMatchHandler );
	}

	handleToggleEvent( trigger: Element, onlyOpen: boolean ): boolean {
		if ( !trigger.hasAttribute( 'accordion-trigger' ) ) {
			return false;
		}

		// Only use events from current mr-accordion
		if (
			!trigger.getAttribute( 'accordion' ) ||
			( trigger.getAttribute( 'accordion' ) !== this.id )
		) {
			return false;
		}

		const isExpanded = 'true' === trigger.getAttribute( 'aria-expanded' );

		if ( !isExpanded ) { // Open panel
			// Set the expanded state on the triggering element
			trigger.setAttribute( 'aria-expanded', 'true' );

			// Hide the accordion sections, using the 'for'-attribute to specify the desired section
			const panel = this.querySelector( '#' + trigger.getAttribute( 'for' ) );

			if ( !panel ) {
				return false;
			}

			panel.removeAttribute( 'hidden' );
			panel.removeAttribute( 'aria-hidden' );

		} else if ( isExpanded && !onlyOpen ) { // Close panel
			// Set the expanded state on the triggering element
			trigger.setAttribute( 'aria-expanded', 'false' );

			// Hide the accordion sections, using the 'for'-attribute to specify the desired section
			const panel = this.querySelector( '#' + trigger.getAttribute( 'for' ) );

			if ( !panel ) {
				return false;
			}

			panel.setAttribute( 'hidden', 'until-found' );
			panel.setAttribute( 'aria-hidden', 'true' );
		}

		return true;
	}

	private findTriggerFromPanelContents( el: Element ): Element|null {
		const ids: Array<string> = [];

		if ( el.id ) {
			ids.push( el.id );
		}

		let walker = el;
		while ( walker.parentNode ) {
			if ( walker === this ) {
				break;
			}

			if ( walker.id ) {
				ids.push( walker.id );
			}

			if ( !walker.parentNode || !( walker.parentNode instanceof Element ) ) {
				break;
			}

			walker = walker.parentNode;
		}

		if ( 0 === ids.length ) {
			return null;
		}

		// Build a query to match triggers. We do not know which ID corresponds to a trigger so we try a lot. We will however only find one thing.
		const query = ids.map( ( id ) => {
			return `[accordion-trigger][for="${id}"]`;
		} ).join( ', ' );

		return this.querySelector( query );
	}
}


const _didAddKeypressEventListener: Map<string, boolean> = new Map();

function addKeypressEventListener( tagName: string ): void {
	if ( _didAddKeypressEventListener.has( tagName ) ) {
		return;
	}

	_didAddKeypressEventListener.set( tagName, true );

	const getTriggers = (): Array<HTMLElement>|null => {
		if ( !document.activeElement ) {
			return null;
		}

		const activeAccordion = document.activeElement.closest( tagName );
		if ( !activeAccordion ) {
			return null;
		}

		const activeAccordionSelector = '[accordion-trigger][accordion="' + activeAccordion.id + '"]';
		const triggers = activeAccordion.querySelectorAll( activeAccordionSelector );

		if ( !triggers || !triggers.length ) {
			return null;
		}

		const out: Array<HTMLElement> = [];
		triggers.forEach( ( el ) => {
			if ( el instanceof HTMLElement ) {
				out.push( el );
			}
		} );

		return out;
	};

	// Handle keydown events
	const keydownHandler = ( event: KeyboardEvent ): void => {
		const target = event.target;
		if ( !target || !( target instanceof HTMLElement ) ) {
			return;
		}

		if ( !target.hasAttribute( 'accordion-trigger' ) ) {
			return;
		}

		const key = event.which.toString();
		if ( !key ) {
			return;
		}

		// 33 = Page Up, 34 = Page Down
		const ctrlModifier = ( event.ctrlKey && key.match( /33|34/ ) );

		// Up/ Down arrow and Control + Page Up/ Page Down keyboard operations
		// 38 = Up, 40 = Down
		if ( key.match( /38|40/ ) || ctrlModifier ) {
			const triggers = getTriggers();
			if ( !triggers ) {
				return;
			}

			let direction = -1;
			if ( key.match( /34|40/ ) ) {
				direction = 1;
			}

			const index = triggers.indexOf( target );
			const length = triggers.length;
			const newIndex = ( index + length + direction ) % length;

			triggers[newIndex].focus();

			event.preventDefault();

			// 35 = End, 36 = Home keyboard operations
		} else if ( key.match( /35|36/ ) ) {
			const triggers = getTriggers();
			if ( !triggers ) {
				return;
			}

			switch ( key ) {

				// Go to first accordion
				case '36':
					triggers[0].focus();
					break;

				// Go to last accordion
				case '35':
					triggers[triggers.length - 1].focus();
					break;
			}

			event.preventDefault();
		}
	};

	document.addEventListener( 'keydown', keydownHandler );
}
