interface VideoSource {
	width: number;
	height: number;
	source: string;
}

export class MrVideo extends HTMLElement {
	connectedCallback() {
		requestAnimationFrame( () => {
			if ( this.closest( 'mr-video-overlay' ) ) {
				return;
			}

			this.setupVideoPlayback();
		} );
	}

	disconnectedCallback() {
		const video = this.getAttachedVideo();
		if ( !video ) {
			return;
		}
	}

	async play() {
		const video = this.getAttachedVideo();
		if ( !video ) {
			return;
		}

		if ( !video.paused ) {
			return;
		}

		try {
			video.addEventListener( 'timeupdate', () => {
				video.setAttribute( 'data-playback-started', '' );
			}, {
				once: true,
			} );

			await video.play();
		} catch ( err ) {
			console.warn( err );
			// Do nothing.
		}
	}

	async pause() {
		const video = this.getAttachedVideo();
		if ( !video ) {
			return;
		}

		video.pause();
	}

	setupVideoPlayback() {
		const video = this.getAttachedVideo();
		if ( !video ) {
			return;
		}

		const videoSources = this.getAttribute( 'video-sources' );
		if ( !videoSources ) {
			return;
		}

		const videos = JSON.parse( videoSources ) as Array<VideoSource> | null;
		if ( !videos || !videos.length ) {
			return;
		}

		videos.sort( ( a, b ) => {
			return a.width - b.width;
		} );

		const width = this.getWidth();
		const height = this.getHeight();

		let selectedVideo = videos[0].source;
		videos.forEach( ( videoSource ) => {
			if ( videoSource.width <= width || videoSource.height <= height ) {
				selectedVideo = videoSource.source;
			}
		} );

		if ( video.src === selectedVideo ) {
			return;
		}

		video.src = selectedVideo;
	}

	getWidth() {
		let multiplier = 1;
		if ( ( 'devicePixelRatio' in window ) && window.devicePixelRatio ) {
			multiplier = Math.max( 1, Math.min( window.devicePixelRatio, 1.5 ) );
		}

		const rect = this.parentElement?.getBoundingClientRect();
		if ( !rect || !rect.width ) {
			return window.innerWidth * multiplier;
		}

		return rect.width * multiplier;
	}

	getHeight() {
		let multiplier = 1;
		if ( ( 'devicePixelRatio' in window ) && window.devicePixelRatio ) {
			multiplier = Math.max( 1, Math.min( window.devicePixelRatio, 1.5 ) );
		}

		const rect = this.parentElement?.getBoundingClientRect();
		if ( !rect || !rect.height ) {
			return window.innerHeight * multiplier;
		}

		return rect.height * multiplier;
	}

	getAttachedVideo(): HTMLVideoElement| null {
		const videoId = this.getAttribute( 'for' );
		if ( !videoId ) {
			return null;
		}

		const video = document.getElementById( videoId );
		if ( !video || !( video instanceof HTMLVideoElement ) ) {
			return null;
		}

		return video;
	}
}

customElements.define( 'mr-video', MrVideo );
