class MrCarousel extends HTMLElement {
	// MARK: properties.

	#currentIndex = 0;

	#clickHandler = ( e: MouseEvent ): void => {
		// Ignore clicks with modifier keys: shift, ctrl, alt,...
		if ( e.metaKey ) {
			return;
		}

		// Check if target exist and is instance of HTMLElement.
		if ( !e.target || !( e.target instanceof HTMLElement ) ) {
			return;
		}

		// Unknown trigger, not handling this event.
		if (
			!e.target.hasAttribute( 'data-carousel-next' ) &&
			!e.target.hasAttribute( 'data-carousel-previous' ) &&
			!e.target.hasAttribute( 'data-carousel-goto' ) &&
			!e.target.hasAttribute( 'data-carousel-view-fullscreen' )
		) {
			return;
		}

		e.preventDefault();
		e.stopPropagation();

		if ( e.target.hasAttribute( 'data-carousel-next' ) ) {
			this.goToNextItem();

			return;
		}

		if ( e.target.hasAttribute( 'data-carousel-previous' ) ) {
			this.goToPreviousItem();

			return;
		}

		if ( e.target.hasAttribute( 'data-carousel-goto' ) ) {
			this.goToItem( e.target.getAttribute( 'data-carousel-goto' ) ?? '0' );

			return;
		}

		if ( e.target.hasAttribute( 'data-carousel-view-fullscreen' ) ) {
			this.toggleFullscreen();

			return;
		}
	};

	#fullscreenHandler = (): void => {
		if ( !( 'requestFullscreen' in this ) ) {
			return;
		}

		if ( document.fullscreenElement !== this ) {
			this.removeAttribute( 'data-shown-fullscreen' );

			const button = this.querySelector<HTMLElement>( '[data-carousel-view-fullscreen]' );
			if ( button ) {
				button.innerText = button.getAttribute( 'data-text-when-closed' ) ?? 'v';
			}
		}
	};

	// MARK: lifecycle.
	connectedCallback() {
		this.addEventListener( 'click', this.#clickHandler );
		this.addEventListener( 'fullscreenchange', this.#fullscreenHandler );

		requestAnimationFrame( () => {
			this.updateStateForSelector( 'item', 0 );
			this.updateStateForSelector( 'goto', 0 );

			if ( !( 'requestFullscreen' in this ) ) {
				( this as HTMLElement ).setAttribute( 'data-no-fullscreen-support', '' );
			}
		} );
	}

	disconnectedCallback() {
		this.removeEventListener( 'click', this.#clickHandler );
		this.removeEventListener( 'fullscreenchange', this.#fullscreenHandler );

		// Reset States.
		this.#currentIndex = 0;
	}

	// MARK: methods.

	private goToItem( index: string ) {
		let newIndex = parseInt( index, 10 );
		if ( newIndex === this.#currentIndex ) {
			return;
		}

		if ( Number.isNaN( newIndex ) ) {
			newIndex = 0;
		}

		if ( 0 > newIndex ) {
			newIndex = 0;
		}

		if ( 0 < newIndex ) {
			const itemsLength = this.querySelectorAll( '[data-carousel-item]' ).length;
			if ( newIndex > itemsLength ) {
				newIndex = itemsLength - 1;
			}
		}

		this.#currentIndex = newIndex;
		this.updateState( newIndex );
	}

	private goToPreviousItem() {
		const items = this.querySelectorAll( '[data-carousel-item]' );
		const newIndex = indexMinusOne( this.#currentIndex, items.length, true );
		if ( newIndex === this.#currentIndex ) {
			return;
		}

		this.#currentIndex = newIndex;
		this.updateState( newIndex );
	}

	private goToNextItem() {
		const items = this.querySelectorAll( '[data-carousel-item]' );
		const newIndex = indexPlusOne( this.#currentIndex, items.length, true );
		if ( newIndex === this.#currentIndex ) {
			return;
		}

		this.#currentIndex = newIndex;
		this.updateState( newIndex );
	}

	private updateState( index: number ) {
		this.updateStateForSelector( 'item', index );
		this.updateStateForSelector( 'goto', index );

		{
			const counter = this.querySelector<HTMLElement>( '[data-carousel-counter]' );
			if ( counter ) {
				let size = parseInt( counter.getAttribute( 'data-size' ) ?? '0', 10 );
				if ( Number.isNaN( size ) ) {
					size = 0;
				}

				counter.innerText = `${index + 1}/${size}`;
			}
		}
	}

	private updateStateForSelector( selector: string, index: number ) {
		const items = this.querySelectorAll( `[data-carousel-${selector}]` );
		const length = items.length;

		// Check if items not available.
		if ( 2 > length ) {
			return;
		}

		// Get current, previous and next items.
		const previous = items[indexMinusOne( index, length, true )];
		const current = items[index];
		const next = items[indexPlusOne( index, length, true )];

		// Set attributes.
		// First reset all items.
		items.forEach( ( item ) => {
			item.removeAttribute( `data-carousel-${selector}-previous` );
			item.removeAttribute( `data-carousel-${selector}-current` );
			item.removeAttribute( `data-carousel-${selector}-next` );

			const label = item.querySelector( 'a[href]' );
			if ( !label || document.activeElement === label ) {
				return;
			}

			label.setAttribute( 'tabindex', '-1' );
		} );

		previous.setAttribute( `data-carousel-${selector}-previous`, '' );
		next.setAttribute( `data-carousel-${selector}-next`, '' );
		current.setAttribute( `data-carousel-${selector}-current`, '' );

		this.eagerAssetLoading( previous );
		this.eagerAssetLoading( next );
		this.eagerAssetLoading( current );
	}

	private eagerAssetLoading( el: Element ) {
		el.querySelectorAll<HTMLImageElement>( 'img[loading=lazy]' ).forEach( ( image ) => {
			image.removeAttribute( 'loading' );
		} );
	}

	private toggleFullscreen() {
		if ( !( 'requestFullscreen' in this ) ) {
			return;
		}

		try {
			if ( document.fullscreenElement ) {
				if ( document.fullscreenElement !== this ) {
					return;
				}

				document.exitFullscreen().then( () => {
					this.removeAttribute( 'data-shown-fullscreen' );

					const button = this.querySelector<HTMLElement>( '[data-carousel-view-fullscreen]' );
					if ( button ) {
						button.innerText = button.getAttribute( 'data-text-when-closed' ) ?? 'v';
					}
				} ).catch( ( err ) => {
					console.warn( err );
				} );

				return;
			}

			this.requestFullscreen().then( () => {
				this.setAttribute( 'data-shown-fullscreen', '' );

				const button = this.querySelector<HTMLElement>( '[data-carousel-view-fullscreen]' );
				if ( button ) {
					button.innerText = button.getAttribute( 'data-text-when-open' ) ?? 'x';
				}
			} ).catch( ( err ) => {
				console.warn( err );
			} );
		} catch ( err ) {
			console.warn( err );
		}

	}
}

customElements.define( 'mr-carousel', MrCarousel );

function indexMinusOne( i: number, maxValue: number, looping: boolean ): number {
	if ( 2 > maxValue ) {
		return 0;
	}

	let index = i;

	index--;

	if ( 0 > index ) {
		if ( looping ) {
			return maxValue - 1;
		}

		return 0;

	}

	return index;
}

function indexPlusOne( i: number, maxValue: number, looping: boolean ): number {
	if ( 2 > maxValue ) {
		return 0;
	}

	let index = i;

	index++;

	if ( index >= maxValue ) {
		if ( looping ) {
			return 0;
		}

		return maxValue - 1;

	}

	return index;
}
